import "../css/colorPicker.css"
import { useState } from 'react'

function ColorPicker({setColor}) {

    const ColorArray = [
        "#333333",
        "#28538f",
        "#d3d3d3",
        "#2a4767",
        "#f490b6",
        "#3b1550",
        "#ff0000",
        "black",
        "#ffffff",
    ]
  return (
    <div className='color-picker'>
        <div className="content">
            {ColorArray.map((color, index) => (
                <div onClick={() => setColor(color)} key={index} className="color-items" style={{backgroundColor: color}}></div>
            ))}

        </div>
    </div>
  )
}

export default ColorPicker