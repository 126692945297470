import { useState, useContext } from 'react'
import ToastContext from '../context/ToastProvider';
import '../css/image.css'
import { BASE_URL } from '../Api/Api';
import axios from 'axios';
import { useText } from '../context/TextProvider';

import CloseIcon from '@mui/icons-material/Close';

function Image({flexDirection, alignItems}) {
    const { handleReset } = useText();
    const { showHideToast } = useContext(ToastContext);
    //  ================ add state ================
    const [addInput, setAddInput] = useState({
        Image: null,
        ImagePreview: null
    })
    //  ================ add state ================

    //  ================ add function ================
    function handleImageChange(event) {
        const imageFile = event.target.files[0];
        // console.log("Selected Image:", imageFile); // Add logging to verify image selection
        setAddInput({
            ...addInput,
            Image: imageFile,
            ImagePreview: URL.createObjectURL(imageFile)
        });
    }

    function removeImage() {
        setAddInput({
            ...addInput,
            Image: null,
            ImagePreview: null
        });
    }
    //  ================ add function ================

    async function handleDialogSubmit(e) {
        e.preventDefault();
        let form = new FormData();
        form.append('_method', 'PUT');
        if (addInput.Image !== null) {
            form.append('value', addInput.Image);
        }

        try {
            const response = await axios.post(`${BASE_URL}`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            // console.log("Response:", response);
            setAddInput({
                Image: null,
                ImagePreview: null
            });
            showHideToast("Added successfully");
        } catch (error) {
            console.log("Error:", error);
            showHideToast(error.response.data.message, 'error');
        }
    }

    const btnIsDisabled = !addInput.Image;

    let btnClasses = ""
    if (btnIsDisabled) {
        btnClasses = "disabled"
    } else {
        btnClasses = "submitbtn"
    }

    return (
        <div className='upload-image' style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <div style={{ width: '100%', position: 'relative' }}>
                <div className='images' onClick={() => document.querySelector('.input-field').click()}>
                    <input onChange={handleImageChange} className='input-field' style={{ display: 'none' }} accept="image/*" type="file" />
                    <div className='image'>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', textAlign: 'center' }}>
                            <h6 style={{ fontSize: '1.125rem', margin: '0', fontWeight: 700 }}>Drop or Select file</h6>
                            <p className='para'>
                                Drop files here or click
                                <span className='browse'>browse</span>
                                thorough your machine
                            </p>
                        </div>
                    </div>
                </div>

                <div className='big-one' style={{ flexDirection: flexDirection ? flexDirection : 'row', justifyContent: 'space-between', alignItems: alignItems ? alignItems : 'center' }}>
                    {addInput.ImagePreview && (
                        <div className='preview'>
                            <span className='prev-image'>
                                <img src={addInput.ImagePreview} alt="" />
                                <button className='close' onClick={() => removeImage()}>
                                    <CloseIcon sx={{ fontSize: '14px', color: 'white' }} />
                                </button>
                            </span>
                        </div>
                    )}
                    <div className='btn'>
                        <button
                            onClick={handleDialogSubmit}
                            disabled={btnIsDisabled}
                            className={btnClasses} variant="contained" type="submit">Upload</button>
                        {window.location.pathname !== '/scan' && (
                            <button
                                onClick={handleReset}
                                className="submitbtn" variant="contained" type="reset">Reset</button>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Image