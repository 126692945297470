import React, { useRef } from 'react';
import '../css/shirt.css';
import domtoimage from 'dom-to-image';


function FrontShirt(props) {
    const {downloadImage, title, width, height, top, right, left, transform, text, secondText, secondTextTop, firstTextSize, firstTextTop, firstTextRight, firstTextLeft, firstTextTransform, color, font, images } = props;

    const handleDownload = () => {
        const screenshot = document.getElementById("all-thing");

        domtoimage.toPng(screenshot)
            .then(function (dataUrl) {
                var anchor = document.createElement("a");
                anchor.setAttribute("href", dataUrl);
                anchor.setAttribute("download", "screenshot.png");
                anchor.click();
                anchor.remove();
            })
            .catch(function (error) {
                console.error('Error generating screenshot:', error);
            });
    }


    return (
        <div className="shirt" id='all-thing'>
            <div className="stack">
                <h3 className="title">{title}-</h3>
                <div className="image-container">
                    <img src={require('../assets/image (1).png')} alt="t-shirt" />
                    <div className='parent-position'>
                        {width && <div className="square" style={{
                            width: width,
                            height: height,
                            top: top,
                            right: right,
                            left: left,
                            transform: transform,
                            color: color,
                            fontFamily: font
                        }}>
                            {images? <img className='image-in-box' onClick={downloadImage} src={images} alt="t-shirt" /> : null}
                            </div>}
                        {typeof text !== 'undefined' && (
                            <p className='text' style={{
                                top: firstTextTop,
                                right: firstTextRight,
                                fontSize: firstTextSize,
                                left: firstTextLeft,
                                transform: firstTextTransform,
                                color: color,
                                fontFamily: font
                            }}>
                                {text ? text : "Your Text"}
                            </p>
                        )}
                        {typeof secondText !== 'undefined' && (
                            <p className='second-text' style={{ top: secondTextTop, color: color, fontFamily: font }}>{secondText ? secondText : "Your Text"}</p>
                        )}
                    </div>
                </div>
            </div>
            <button id="d1-png" onClick={handleDownload}>Download Design</button>
        </div>
    );
}

export default FrontShirt;
