import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useState } from 'react'
import "../css/fontfamilyselect.css"

function FontFamilySelect({setFont, font}) {


    const fontArray = [
        "blkchcry",
        "Birds of Paradise",
        "ObelixPro-cyr",
        "creepsville",
        "athletic",
        "Inkfree",
        "adrip",
        "waltograph",
        "ComicSans",
        "OldeEnglish",
        "AVENGEANCE",
        "BacktoBlack",
        "Albertson",
        "Freshman",
        "Autumn",
        "impact",
        "BrannbollFS_PERSONAL",
        "hemi",
        "showg",
        "arial"
    ]
    return (
        <div className='font-family-select'>
            <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Select Font</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={font}
                    label="Select Font"
                    onChange={(e) => setFont(e.target.value)}
                >
                    {fontArray.map((font, index) => (
                        <MenuItem style={{ fontFamily: font }} key={index} value={font}>{font}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default FontFamilySelect