import { useState, useEffect } from 'react'
import '../css/product.css';
import FrontShirt from '../components/FrontShirt';
import BackShirt from '../components/BackShirt';
import Image from '../components/Image';
import ColorPicker from '../components/ColorPicker';
import FontFamilySelect from '../components/FontFamilySelect';
import axios from 'axios';
import { BASE_URL } from '../Api/Api';
import { saveAs } from 'file-saver';


import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import { useText } from '../context/TextProvider';


function Product() {
    const {images, setimages, color, setColor, font, setFont, text, setText, text1, setText1, text2, setText2, text3, setText3, text4, setText4, text5, setText5, text6, setText6 } = useText();

    useEffect(() => {
        const fetchData = () => {
            axios.get(BASE_URL)
                .then(function (response) {
                    setimages(response.data.value);
                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        // Fetch data initially
        fetchData();

        // Fetch data every 5 seconds
        const interval = setInterval(fetchData, 2000);

        // Cleanup function to clear interval
        return () => clearInterval(interval);
    }, []);

    const downloadImage = () => {
        saveAs(images, 'image.png');
        console.log("Image downloaded", images);
    }

    return (
        <div className="product">
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid xs={12} md={6} >
                    <div style={{position: "absolute", left: 0}}>
                                <img style={{ width: "100px", aspectRatio: "1/1" }} src={require('../assets/qrcode.jpeg')} alt="qrCode" />
                            </div>
                        <h2 className="main-header">Front</h2>
                        <div className="center">
                            <FrontShirt downloadImage={downloadImage} images={images} font={font} color={color} title="1" width="25px" height="20px" top="30px" right="40px" />
                            <FrontShirt downloadImage={downloadImage} images={images} font={font} color={color} title="2" width="60px" height="50px" top="30px" left="51%" transform="translateX(-50%)" />
                        </div>
                        <div className="center">
                            <FrontShirt font={font} color={color} title="3" text={text} firstTextTop="20px" firstTextLeft="50%" firstTextTransform="translateX(-50%)" secondText={text1} secondTextTop="40px" />
                            <FrontShirt downloadImage={downloadImage} images={images} font={font} color={color} title="4" text={text} firstTextTop="20px" firstTextLeft="50%" firstTextTransform="translateX(-50%)" secondText={text2} secondTextTop="100px" width="60px" height="50px" top="45px" left="51%" transform="translateX(-50%)" />
                        </div>
                        <div className="two-field">
                            <TextField sx={{ width: "40%" }} value={text1} onChange={(e) => setText1(e.target.value)} id="outlined-basic1" label="Text Here" variant="outlined" size="small" />
                            <TextField sx={{ width: "40%" }} value={text2} onChange={(e) => setText2(e.target.value)} id="outlined-basic2" label="Text Here" variant="outlined" size="small" />
                        </div>
                        <div className="center">
                            <FrontShirt font={font} color={color} title="5" text={text} firstTextTop="25px" firstTextSize="8px" firstTextRight="40px" />
                            <FrontShirt downloadImage={downloadImage} images={images} font={font} color={color} title="6" width="60px" height="50px" top="30px" left="51%" transform="translateX(-50%)" text={text} firstTextTop="86px" firstTextLeft="50%" firstTextTransform="translateX(-50%)" />
                        </div>
                        <div className="upload-contain">
                            <Image />
                        </div>

                    </Grid>

                    <Grid xs={12} md={6} sx={{ borderLeft: "2px dashed #06036E" }}>
                        <h2 className="main-header">Back</h2>
                        <div className="center">
                            <BackShirt images={images} font={font} color={color} title="A" width="25px" height="20px" top="20px" left="50%" transform="translateX(-50%)" />
                            <BackShirt images={images} font={font} color={color} title="B" width="60px" height="50px" top="30px" left="51%" transform="translateX(-50%)" />
                        </div>
                        <div className="center">
                            <BackShirt images={images} font={font} color={color} title="C" text={text} firstTextTop="20px" firstTextLeft="50%" firstTextTransform="translateX(-50%)" secondText={text3} secondTextTop="100px" width="60px" height="50px" top="45px" left="51%" transform="translateX(-50%)" />
                            <BackShirt font={font} color={color} title="D" text={text} firstTextTop="20px" firstTextLeft="50%" firstTextTransform="translateX(-50%)" secondText={text6} secondTextTop="40px" />

                        </div>
                        <div className="two-field">
                            <TextField sx={{ width: "40%" }} value={text3} onChange={(e) => setText3(e.target.value)} id="outlined-basic3" label="Text Here" variant="outlined" size="small" />
                            <TextField sx={{ width: "40%" }} value={text6} onChange={(e) => setText6(e.target.value)} id="outlined-basic6" label="Text Here" variant="outlined" size="small" />
                        </div>
                        <div className="center">
                            <BackShirt font={font} color={color} title="E" name={text4} number={text5} />
                            <BackShirt images={images} font={font} color={color} title="F" width="60px" height="50px" top="30px" left="51%" transform="translateX(-50%)" text={text} firstTextTop="86px" firstTextLeft="50%" firstTextTransform="translateX(-50%)" />
                        </div>
                        <div className="two-field2">
                            <div className="column">
                                <TextField value={text4} onChange={(e) => setText4(e.target.value)} id="outlined-basic4" label="Name" variant="outlined" size="small" />
                                <TextField value={text5} onChange={(e) => setText5(e.target.value)} id="outlined-basic5" label="Number" variant="outlined" size="small" />
                            </div>
                        </div>
                        <div className='my-input'>
                            <div className="stack">
                                <h3 style={{ minWidth: "fit-content" }}>Enter Text: </h3>
                                <TextField fullWidth value={text} onChange={(e) => setText(e.target.value)} id="outlined-basic" label="Text Here" variant="outlined" size="small" />
                            </div>
                            <div className='stack'>
                                <h3 >Select Color: </h3>
                                <ColorPicker setColor={setColor} color={color} />
                            </div>
                            <div className="stack">
                                <h3 style={{ minWidth: "fit-content" }}>Select Font:</h3>
                                <FontFamilySelect setFont={setFont} font={font} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>

        </div>
    )
}

export default Product
