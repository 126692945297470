import '../css/shirt.css'

function BackShirt(props) {
    const { title, width, height, top, right, left, transform, text, secondText, secondTextTop, firstTextSize, firstTextTop, firstTextRight, firstTextLeft, firstTextTransform, name, number, color, font, images } = props
  return (
    <div className="shirt">
            <div className="stack">
                <h3 className="title">{title}-</h3>
                <div className="image-container">
                    <img src={require('../assets/image.png')} alt="t-shirt" />
                    <div className='parent-position'>
                        {width && <div className="square" style={{
                            width: width,
                            height: height,
                            top: top,
                            right: right,
                            left: left,
                            transform: transform,
                            color: color,
                            fontFamily: font
                        }}>{images? <img className='image-in-box' src={images} alt="t-shirt" /> : null}</div>}
                        {typeof text !== 'undefined' && (
                            <p className='text' style={{
                                top: firstTextTop,
                                right: firstTextRight,
                                fontSize: firstTextSize,
                                left: firstTextLeft,
                                transform: firstTextTransform,
                                color: color,
                                fontFamily: font
                            }}>
                                {text ? text : "Your Text"}
                            </p>
                        )}
                        {typeof secondText !== 'undefined' && (
                            <p className='second-text' style={{ top: secondTextTop, color: color, fontFamily: font }}>{secondText ? secondText : "Your Text"}</p>
                        )}
                        {typeof name !== 'undefined' && (
                            <p className='football-name' style={{ color: color, fontFamily: font }}>{name ? name : "NAME"}</p>
                        )}
                        {typeof number !== 'undefined' && (
                            <p className='football-num' style={{ color: color, fontFamily: font }}>{number ? number : "00"}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
  )
}

export default BackShirt