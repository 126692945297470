import './App.css';
import Product from './Pages/Product';
import QrCode from './Pages/QrCode';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Product />} />
        <Route path="/Scan" element={<QrCode />} />
      </Routes>
    </div>
  );
}

export default App;
