import { createContext, useContext, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../Api/Api";


export const TextContext = createContext()

const TextProvider = ({ children }) => {
    const [images, setimages] = useState("");
    const [color, setColor] = useState()
    const [font, setFont] = useState();
    const [text, setText] = useState("")
    const [text1, setText1] = useState("")
    const [text2, setText2] = useState("")
    const [text3, setText3] = useState("")
    const [text4, setText4] = useState("")
    const [text5, setText5] = useState("")
    const [text6, setText6] = useState("")
    // const [runuseEffect, setrunuseEffect] = useState(0)

    async function handleDeleteConfirm() {
        try {
          const response = await axios.delete(`${BASE_URL}`);
          // console.log("Image deleted:", response.data);
          
        } catch (error) {
          console.log(error);
        }
      }

    function handleReset(e) {
        e.preventDefault();
        setColor("")
        setFont("")
        setText("")
        setText1("")
        setText2("")
        setText3("")
        setText4("")
        setText5("")
        setText6("")
        setimages("")
        handleDeleteConfirm()
    }



    return (
        <TextContext.Provider value={{images, setimages, color, setColor, font, setFont, text, setText, text1, setText1, text2, setText2, text3, setText3, text4, setText4, text5, setText5, text6, setText6, handleReset }}>
            {children}
        </TextContext.Provider>
    )
}

export default TextProvider

// useHook
export const useText = () => {
    return useContext(TextContext)
}