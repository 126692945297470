import React from 'react'
import Image from '../components/Image'
import "../css/qrcode.css"

function QrCode() {
  return (
    <div className="qrcode">
        <Image flexDirection="column-reverse" alignItems="flex-start" />
    </div>
  )
}

export default QrCode